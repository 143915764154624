var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-view',{attrs:{"title":"绑定手机","left-arrow":"","white-bg":""}},[(_vm.shouldShowForm)?_c('van-form',{attrs:{"show-error":true,"show-error-message":false,"scroll-to-error":true,"validate-first":true},on:{"submit":_vm.onSubmit,"failed":_vm.onFailed}},[_c('div',{staticClass:"driver-box"}),_c('van-field',{attrs:{"name":"phone","label":"手机号","type":"tel","maxlength":11,"formatter":_vm.formatterNumber,"placeholder":"请输入手机号","rules":[
        { required: true, message: '请输入手机号' },
        { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
      ]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"name":"sms","center":"","clearable":"","label":"验证码","placeholder":"请输入短信验证码"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('sms-code-button',{on:{"click":_vm.getSMSCode},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]},proxy:true}],null,false,96364705),model:{value:(_vm.form.sms),callback:function ($$v) {_vm.$set(_vm.form, "sms", $$v)},expression:"form.sms"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"block":"","type":"primary","native-type":"submit","loading":_vm.submiting,"disabled":_vm.submiting,"loading-text":"正在提交..."}},[_vm._v("提 交")])],1)],1):_c('div',{style:({
      display: 'flex',
      flexDirection: 'column',
      flex: 'auto',
      alignItems: 'center',
      margin: '32px 16px'
    })},[_c('img',{style:({width: '220px'}),attrs:{"src":require("@/assets/images/my/bind-mobile.jpg")}}),_c('p',{style:({margin: '20px 0'})},[_c('svg-icon',{style:({color: '#1989fa'}),attrs:{"icon-class":"link"}}),_c('span',{staticClass:"ml-10"},[_vm._v("已绑定号码："+_vm._s(this.phone))])],1),_c('van-button',{attrs:{"block":"","type":"primary"},on:{"click":function($event){_vm.isEditMode = true}}},[_vm._v("更换手机号")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }